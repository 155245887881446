<template>
  <div class="text-center">
    <line-chart v-if="loaded"
         :chart-id="chartId"
         :chart-data="chartData"
         :chart-options="chartOptions"
         :width="width"
         :height="height"
    />
    <span v-else><a-icon type="loading" /></span>
  </div>
</template>
<script>
import { Line as LineChart } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

export default {
  name: 'CurrencyRateHistoryChart',
  // props: ['agentId', 'fromDate', 'toDate'],
  props: {
    chartId: {
      type: String,
      default: 'line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Rate',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 100,
    },
    fromDate: {
      type: String,
      default: '',
    },
    toDate: {
      type: String,
      default: '',
    },
    bottomLabels: {
      type: Boolean,
      default: false,
    },
    withAnimation: {
      type: Boolean,
      default: false,
    },
  },
  components: { LineChart },
  computed: {
    minValue() {
      return Math.min(...this.items)
    },
    maxValue() {
      // console.log(Math.max(...this.items), 'max')
      return Math.max(...this.items)
    },
  },
  data: () => ({
    loaded: false,
    chartData: null,
    // min: Math.min(...this.items),
    // max: Math.max(...this.items),
    chartOptions: {
      // animation: this.withAnimation,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      elements: {
        point: {
          radius: 1.5,
          border: 0,
        },
      },
      scales: {
        x: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            display: true,
            callback: function(val, index) {
              return (val === this.min || val === this.max) && val !== 0 && val !== 1 ? this.getLabelForValue(val) : ''
            },
            color: '#bbb',
            font: {
              size: 10,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
    },
  }),
  async mounted () {
    this.loaded = false

    try {
      const labels = this.labels ? this.labels : []
      const digits = this.items ? this.items : []

      // this.minValue = Math.min(...digits)
      // this.maxValue = Math.max(...digits)

      console.log(labels, 'labels')
      console.log(digits, 'data')
      this.chartData = {
        datasets: [{ data: digits, label: this.title, backgroundColor: '#eee', borderColor: '#bbb', borderSize: 0, borderWidth: 1, fill: false }],
        labels: labels,
      }

      this.loaded = true
    } catch (e) {
      console.error(e)
    }
  },
}
</script>

<style scoped lang="scss">

</style>

<template>
  <div>
    <a-form-model
      ref="currencyRateForm"
      :model="currencyRateForm"
      :rules="currencyRateRules"
    >
      <div v-if="1 === 0" class="import-type mb-3">
        <a-form-model-item ref="import_type" :label="`${$t('common.import')} ${$t('buttons.btnType')}`" prop="import_type">
          <a-radio-group v-model="currencyRateForm.import_type" button-style="solid" class="w-100">
            <a-radio-button v-for="method in importMethods" :value="method" :key="'rate_import_method' + method" class="w-50 text-center">
              {{ method }}
            </a-radio-button>
<!--            <a-radio-button value="binance" class="w-50 text-center">-->
<!--              Binance-->
<!--            </a-radio-button>-->
          </a-radio-group>
        </a-form-model-item>
      </div>
      <a-row>
        <a-col :span="8">
          <a-statistic :title="`${$t('common.base')} ${$t('common.rate')}`" :value="rates.base" style="margin-right: 50px">
            <template #suffix>
              <span class="small">{{ currency.abbr }}</span>
            </template>
          </a-statistic>
          <div class="d-block mt-2">
            <a href="javascript:" class="text-primary" @click="refreshBaseRate">
              <a-icon v-if="!refreshBaseLoading" type="reload" />
              <a-icon v-else type="loading" />
              Refresh Base
            </a>
          </div>
        </a-col>
        <a-col :span="8">
          <span class="" :style="'color: #8C8C8C'">Buy</span>
          <a-statistic title="" :value="rates.rateB" style="margin-right: 50px">
            <template #suffix>
              <span class="small">{{ currency.abbr }}</span>
            </template>
            <template #prefix>
              <span class="font-size-14 text-gray-5">Client:</span>
            </template>
          </a-statistic>
          <a-statistic title="" :value="rates.rateBa" style="margin-right: 50px">
            <template #suffix>
              <span class="small">{{ currency.abbr }}</span>
            </template>
            <template #prefix>
              <span class="font-size-14 text-gray-5">Agent:</span>
            </template>
          </a-statistic>
        </a-col>
        <a-col :span="8">
          <span class="" :style="'color: #8C8C8C'">Sell</span>
          <a-statistic title="" :value="rates.rateS" style="margin-right: 50px">
            <template #suffix>
              <span class="small">{{ currency.abbr }}</span>
            </template>
            <template #prefix>
              <span class="font-size-14 text-gray-5">Client:</span>
            </template>
          </a-statistic>
          <a-statistic title="" :value="rates.rateSa" style="margin-right: 50px">
            <template #suffix>
              <span class="small">{{ currency.abbr }}</span>
            </template>
            <template #prefix>
              <span class="font-size-14 text-gray-5">Agent:</span>
            </template>
          </a-statistic>
        </a-col>
      </a-row>
      <a-card :title="`${$t('common.rate')} adjustments`" class="mt-3">
        <a-row>
          <a-col :span="12" class="pl-1 pr-2">
            <span class="text-uppercase">{{ $t('buttons.btnBuy') }} {{ $t('common.operation') }}</span>
            <a-row class="mt-3">
              <a-col :span="12" class="pr-1">
                <a-form-model-item ref="rate_b_inc" label="Client" prop="rate_b_inc" >
                  <a-input-number
                    v-model="currencyRateForm.rate_b_inc"
                    class="w-100"
                    :default-value="0"
                    :step="0.01"
                    :min="-100"
                    :max="100"
                    :formatter="value => `${value} %`"
                    :parser="value => value.replace(' %', '').replace('%', '')"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12" class="pl-1">
                <a-form-model-item ref="rate_ba_inc" label="Agent" prop="rate_ba_inc" >
                  <a-input-number
                    v-model="currencyRateForm.rate_ba_inc"
                    class="w-100"
                    :default-value="0"
                    :step="0.01"
                    :min="-100"
                    :max="100"
                    :formatter="value => `${value} %`"
                    :parser="value => value.replace(' %', '').replace('%', '')"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="12" class="pl-2">
            <span class="text-uppercase">{{ $t('buttons.btnSell') }} {{ $t('common.operation') }}</span>
            <a-row class="mt-3">
              <a-col :span="12" class="pr-1">
                <a-form-model-item ref="rate_s_inc" label="Client" prop="rate_s_inc" >
                  <a-input-number
                    v-model="currencyRateForm.rate_s_inc"
                    class="w-100"
                    :default-value="0"
                    :step="0.01"
                    :min="-100"
                    :max="100"
                    :formatter="value => `${value} %`"
                    :parser="value => value.replace(' %', '').replace('%', '')"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12" class="pl-1">
                <a-form-model-item ref="rate_sa_inc" label="Agent" prop="rate_sa_inc" >
                  <a-input-number
                    v-model="currencyRateForm.rate_sa_inc"
                    class="w-100"
                    :default-value="0"
                    :step="0.01"
                    :min="-100"
                    :max="100"
                    :formatter="value => `${value} %`"
                    :parser="value => value.replace(' %', '').replace('%', '')"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-card>
    </a-form-model>
    <a-row class="mt-4">
      <a-col :span="14">
        <a-button @click="closeModal">
          {{ $t('buttons.btnCancel') }}
        </a-button>
      </a-col>
      <a-col :span="10" class="text-right">
        <a-button type="primary" @click="onSubmit" :loading="submitLoading">
          {{ $t('buttons.btnSave') }}
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import ApiService from '@/services/api/api.service'

export default {
  name: 'currencyRateModal.vue',
  props: ['currencyRateInfo', 'currency', 'importMethods'],
  computed: {
    ratePropertiesStr() {
      return `${this.base}|${this.rateBInc}|${this.rateBAInc}|${this.rateSInc}|${this.rateSAInc}`
    },
    base() {
      return this.rates.base
    },
    rateBInc() {
      return this.currencyRateForm.rate_b_inc
    },
    rateBAInc() {
      return this.currencyRateForm.rate_ba_inc
    },
    rateSInc() {
      return this.currencyRateForm.rate_s_inc
    },
    rateSAInc() {
      return this.currencyRateForm.rate_sa_inc
    },
  },
  watch: {
    ratePropertiesStr: function(val) {
      if (this.base > 0) {
        this.rates.rateB = (this.base + (this.base * this.rateBInc / 100)).toFixed(2)
        this.rates.rateBa = (this.base + (this.base * this.rateBAInc / 100)).toFixed(2)
        this.rates.rateS = (this.base + (this.base * this.rateSInc / 100)).toFixed(2)
        this.rates.rateSa = (this.base + (this.base * this.rateSAInc / 100)).toFixed(2)
      }
      console.log('recalculated')
    },
  },
  data() {
    return {
      submitLoading: false,
      refreshBaseLoading: false,
      rates: {
        base: this.currencyRateInfo.base,
        rateB: this.currencyRateInfo.rate_b,
        rateBa: this.currencyRateInfo.rate_ba,
        rateS: this.currencyRateInfo.rate_s,
        rateSa: this.currencyRateInfo.rate_sa,
      },
      currencyRateForm: {
        // import_type: this.currency.imp_r_type ? this.currency.imp_r_type : 'default',
        rate_b_inc: this.currencyRateInfo ? this.currencyRateInfo.rate_b_inc : 0,
        rate_ba_inc: this.currencyRateInfo ? this.currencyRateInfo.rate_ba_inc : 0,
        rate_s_inc: this.currencyRateInfo ? this.currencyRateInfo.rate_s_inc : 0,
        rate_sa_inc: this.currencyRateInfo ? this.currencyRateInfo.rate_sa_inc : 0,
      },
      currencyRateRules: {
        // name: [{ required: true, message: 'Укажите ISO код', trigger: 'change' }],
        // abbr: [{ required: true, message: 'Укажите символ валюты', trigger: 'change' }],
      },
    }
  },
  mounted () {
    // ---
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record) {
      this.$emit('submitModalFunction', record)
    },
    submitBaseChanged(record) {
      this.$emit('updateBaseRateFunction', record)
    },
    onSubmit() {
      this.$refs.currencyRateForm.validate(valid => {
        if (valid) {
          this.editRateSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.currencyRateForm.resetFields()
    },
    async editRateSubmit() {
      this.submitLoading = true
      return ApiService.updateRateCharges(this.currencyRateInfo.id, this.currencyRateForm).then((response) => {
        this.$message.success('Currency rates updated')
        this.submitLoading = false
        this.submitModal(response.data.data)
      }).catch(error => {
        console.log(error)
        this.$message.error('Can not update rates. Message: '.error.message)
        this.submitLoading = false
      })
    },
    async refreshBaseRate() {
      this.refreshBaseLoading = true
      return ApiService.refreshBaseRate(this.currencyRateInfo.id).then((response) => {
        this.rates.base = response.data.data.rate.base
        this.submitBaseChanged(response.data.data)

        // console.log(this.rates.base)
        this.refreshBaseLoading = false
        this.$message.success('Base rate updated')
      }).catch(error => {
        this.$message.error('Can not get updated base rate')
        this.refreshBaseLoading = false
        console.log(error)
      })
    },
  },
}
</script>
<style lang="scss">
</style>
<style scoped>
</style>

<template>
  <div>
    <h4 style="padding-bottom: 12px"><a-icon type="setting" /> {{ $t('common.currencies') }} & {{ $t('common.rates') }}</h4>
    <a-row>
      <a-col :span="24" class="pl-1">
        <a-card class="">
          <currencies-table />
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import currenciesTable from '@/views/currencies/currencies/currenciesTable.vue'

export default {
  name: 'currencies',
  components: {
    currenciesTable,
  },
  data() {
    return {
      //
    }
  },
  mounted () {
    //
  },
  methods: {
    //
  },
}

</script>
<style>
  .card-h-560 {
    min-height: 560px;
    max-height: 560px;
  }
</style>
<style scoped>
  .editable-row-operations a {
    margin-right: 8px;
  }
</style>
